<template>
  <div class="container">
    <uiTopPageSection
      :src="backgroundImage"
      :bgPosition="{ x: '70%', y: '50%' }"
    >
      <div class="top-content-section">
        <p class="v_margin_small text_small">
          {{ $t("aboutArmor.topPage.p1") }}
        </p>
        <h1 class="text_super_big">DEMIDOV ARMOR</h1>
        <p v-scrollanimate="'bottom'" class="v_margin_small text_small">
          {{ $t("aboutArmor.topPage.p2") }}
        </p>
      </div>
    </uiTopPageSection>
    <section class="about-container-first light">
      <div class="about-container-content padding_small">
        <img src="/aboutArmor/men_in_armor.jpg" alt="" />
        <div class="semi_block_max_width_right">
          <h2 class="text_small text_justify">
            {{ $t("aboutArmor.section1.p1-1") }}
            <span class="text_bold">
              {{ $t("aboutArmor.section1.p1-2") }}
            </span>
            {{ $t("aboutArmor.section1.p1-3") }}
          </h2>
          <div class="video-link">
            <router-link
                    tag="a"
                    exact="true"
                    class="video-link__href"
                    :to="'/' + this.$i18n.locale + '/video_1'"
            >{{$t("messages.video")}} <font-awesome-icon :icon="['fab', 'youtube']" /></router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="about-under-dress light b_padding_medium">
      <div class="about-under-dress-items">
        <div class="about-under-dress-img about-under-dress-item">
          <div class="about-under-dress-img-wrapper"></div>
        </div>
        <div class="max_with about-under-dress-item">
          <div class="padding_medium semi_block_max_width_left">
            <p v-scrollanimate="'bottom'" class="text_small text_justify">
              {{ $t("aboutArmor.section2.p1") }}
            </p>
            <ui-targets-list :items="targetsList" />
            <h2 v-scrollanimate="'bottom'" class="text_small text_bold text_justify">
              {{ $t("aboutArmor.section2.p2") }}
            </h2>
          </div>
        </div>
      </div>

      <div class="h_padding_small armor_table">
        <ui-table :table-head="tableHead" :table-data="tableData" />
      </div>
    </section>
    <section class="about-guns">
      <div class="desktop_pistol about-gins-item">
        <div>
          <img src="/aboutArmor/44-mag-revolver.png" alt="" />
        </div>
      </div>
      <div class="section dark about-gins-item">
        <h2 v-scrollanimate="'bottom'" class="text_small text_italic text_justify">
          {{ $t("aboutArmor.section3.p1") }}
        </h2>
      </div>
    </section>
    <section class="padding_medium about-graph">
      <div class="max_with about-graph-items">
        <img class="v_margin_small" src="/aboutArmor/graph.svg" alt="" />
        <h2 v-scrollanimate="'bottom'" class="text_small padding_medium text_justify">
          {{ $t("aboutArmor.section4.p1") }}
        </h2>
      </div>

    </section>
    <section class="about-principles section">
      <h2 class="text_medium text_center v_margin_small text_bold">
        {{ $t("aboutArmor.section5.p1-1") }}
        <span class="text_orange">{{ $t("aboutArmor.section5.p1-2") }}</span>
        {{ $t("aboutArmor.section5.p1-3") }}
      </h2>
      <div class="about-principles-items b_margin_big">
        <div class="about-principles-item">
          <img src="/aboutArmor/front_view_armor.jpg" alt="" />
          <p
            class="
              about-principles-img-text
              text_small
              h_padding_small
              text_center
            "
          >
            {{ $t("aboutArmor.section5.p2-1") }}
            <span class="text_orange text_bold">{{
              $t("aboutArmor.section5.p2-2")
            }}</span
            >{{ $t("aboutArmor.section5.p2-3") }}
          </p>
        </div>
        <div class="about-principles-item">
          <img src="/aboutArmor/left_view_armor.jpg" alt="" />
          <p
            class="
              about-principles-img-text
              text_small
              h_padding_small
              text_center
            "
          >
            {{ $t("aboutArmor.section5.p3-1") }}
            <span class="text_orange text_bold">{{
              $t("aboutArmor.section5.p3-2")
            }}</span
            >{{ $t("aboutArmor.section5.p3-3") }}
          </p>
        </div>
      </div>
    </section>
    <section class="about-minuses section light">
      <h2 v-scrollanimate="'bottom'" class="text_medium text_center text_bold">
        {{ $t("aboutArmor.section6.p1") }}
      </h2>
      <div class="about-minuses-items">
        <div
          class="about-minuses-item"
          v-for="(item, index) in minusesItems"
          :key="index"
        >
          <img v-scrollanimate="'right'" :src="item.src" alt="" />
          <p v-scrollanimate="'left'" class="text_small text_center">
            {{ $t(item.text) }}
          </p>
        </div>
      </div>
      <p
        v-scrollanimate="'bottom'"
        class="text_bold text_center text_small v_margin_small"
      >
        {{ $t("aboutArmor.section6.p2") }}
      </p>
    </section>
  </div>
</template>

<script>
import backgroundImage from "@/assets/aboutArmor/about_bg_image.jpg";
import uiTable from "../components/UI/uiTable";
import uiTargetsList from "../components/UI/uiTargetsList";
import uiTopPageSection from "../components/UI/uiTopPageSection";

export default {
  name: "AboutArmor",
  components: {
    uiTable,
    uiTargetsList,
    uiTopPageSection,
  },
  data() {
    return {
      backgroundImage,
      tableHead: [
        "aboutArmor.section2.tableHead[0]",
        "aboutArmor.section2.tableHead[1]",
        "aboutArmor.section2.tableHead[2]",
        "aboutArmor.section2.tableHead[3]",
      ],
      tableData: [
        ["USA  (NIJ 0101.04)", "IIIA", "44Magnum", "45mm"],
        ["EU (CEN EN 1063)", "BR4", "44Magnum", "25mm"],
        ["RU (ГОСТ Р 50744-95)", "2", "TT", "20mm"],
      ],
      targetsList: [
        "aboutArmor.section2.targetsList[0]",
        "aboutArmor.section2.targetsList[1]",
        "aboutArmor.section2.targetsList[2]",
      ],
      minusesItems: [
        {
          src: "/aboutArmor/layers.svg",
          text: "aboutArmor.section6.minusesItems[0]",
        },
        {
          src: "/aboutArmor/bend.svg",
          text: "aboutArmor.section6.minusesItems[1]",
        },
        {
          src: "/aboutArmor/trauma.svg",
          text: "aboutArmor.section6.minusesItems[2]",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "../vars";
.about-container-first {
  display: flex;
  .about-container-content {
    margin: auto;
    background-color: white;
    width: 80%;
    position: relative;
    top: -40px;
    img {
      max-width: 100%;
      margin-bottom: 25px;
    }
  }
}
.about-under-dress {
  .about-under-dress-img-wrapper {
    background: linear-gradient(90deg, #141414 0%, rgba(20, 20, 20, 0.04) 100%);
    height: 100%;
  }
  .about-under-dress-img {
    background-image: url("/aboutArmor/armor_under_dress.jpg");
    height: 200px;
    background-position: 50% 10%;
    background-size: cover;
  }
}
.about-guns {
  line-height: 1.5;
  color: white;
  .desktop_pistol {
    display: none;
  }
}
.about-graph {
  background-color: #eeeeee;
  img {
    height: auto;
    width: 100%;
  }
}
.about-principles {
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    filter: contrast(115%);
  }
  .about-principles-img-text {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .about-principles-item {
    position: relative;
    z-index: 100;
  }
}
.about-minuses {
  .about-minuses-item {
    display: flex;
    flex-direction: column;
    img {
      width: 60px;
      margin: 25px auto;
    }
    span {
      text-align: center;
    }
  }
}

@media (min-width: $medium_w) {
  .about-under-dress {
    .about-under-dress-img {
      height: 400px;
    }
  }
}
@media (min-width: $large_w) {
  .about-container-first {
    .about-container-content {
      display: flex;
      flex-direction: row;
      padding: 0;
      width: auto;
      position: initial;
      img {
        object-fit: cover;
        width: 50%;
        height: auto;
        margin: 0;
      }
      div {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding: 50px;
      }
    }
  }
  .about-under-dress {
    position: relative;
    .about-under-dress-img {
      background-size: cover;
      height: 800px;
      background-position: 55% 0;
      background-repeat: no-repeat;
    }
    .about-under-dress-img-wrapper {
      background: linear-gradient(
        90deg,
        #141414 10%,
        rgba(20, 20, 20, 0.04) 100%
      );
    }
    .about-under-dress-items {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      align-content: space-between;
      &:first-child {
        background-color: #141414;
        color: white;
      }
      .about-under-dress-item {
        flex-grow: 1;
        flex-basis: 0;
      }
    }
    .armor_table {
      position: relative;
      top: -100px;
    }
  }
  .about-guns {
    display: flex;
    flex-direction: row;
    .about-gins-item {
      flex-grow: 1;
      flex-basis: 0;
      align-items: center;
      display: flex;
    }
    .desktop_pistol {
      justify-content: flex-end;
      img {
        max-width: 100%;
        z-index: 100;
        position: relative;
        right: -80px;
      }
    }
    h2 {
      font-size: 21px;
      padding: 20px;
      max-width: 800px;
    }
  }
  .about-graph {
    .about-graph-items{
      display: flex;
      flex-direction: row-reverse;
      h2 {
        margin: auto;
        flex-grow: 1;
      }
      img {
        width: 50%;
        flex-grow: 1;
      }
    }

  }
  .about-principles {
    .about-principles-items {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .about-principles-item {
      padding: 15px;
      img {
        max-width: 600px;
      }
    }
  }
  .about-minuses {
    .about-minuses-items {
      margin: auto;
      max-width: 1600px;
      flex-direction: row;
      display: flex;
    }
    .about-minuses-item {
      flex-grow: 1;
      flex-basis: 0;
      padding: 25px;
      img {
        width: 100px;
      }
    }
  }
}
</style>
